<template>
  <moe-page title="拼团活动列表">
    <!-- 筛选信息 -->
    <moe-inquire @search="activitySearch">
      <el-form-item label="活动名称">
        <el-input v-model.trim="activityParams.name" placeholder="输入活动名称" maxlength="50" clearable />
      </el-form-item>
      <el-form-item label="活动状态">
        <moe-select type="groupActivityState" v-model="activityParams.state" placeholder="选择活动状态" clearable></moe-select>
      </el-form-item>
    </moe-inquire>

    <moe-table ref="activityTable" url="/shop/group/list" :params="activityParams" :numberShow="false">
      <!-- 操作按钮 -->
      <template slot="tool">
        <el-button type="primary" icon="el-icon-circle-plus-outline"
          @click="$moe_coordinator.navigateTo('/activityManage/group/add')">新建拼团活动</el-button>
      </template>

      <el-table-column label="活动ID" prop="id" />
      <el-table-column label="活动名称" prop="name" :show-overflow-tooltip="true" />

      <el-table-column label="成团人数">
        <template slot-scope="{ row }">
          {{ `${row.num}人成团` }}
        </template>
      </el-table-column>
      <el-table-column label="活动开始时间" width="150">
        <template slot-scope="{ row }">
          {{ $moe_time.getTimeStamp(row.startTime) }}
        </template>
      </el-table-column>
      <el-table-column label="活动结束时间" width="150">
        <template slot-scope="{ row }">
          {{ $moe_time.getTimeStamp(row.endTime) }}
        </template>
      </el-table-column>
      <el-table-column label="商品数量" prop="goodsNum" />
      <el-table-column label="状态">
        <div :class="$moe_format.getGroupActivityStateColor(row.state)" slot-scope="{ row }">
          {{ $moe_format.getGroupActivityState(row.state) }}
        </div>
      </el-table-column>

      <el-table-column label="操作" fixed="right" width="400">
        <div class="moe-table_btns" slot-scope="{ row }">
          <el-button type="success" size="mini" icon="el-icon-document" @click="$moe_coordinator.navigateTo(`/activityManage/group/detail`, { id: row.id })">查看</el-button>

          <el-button v-if="!['FINISHED', 'CLOSED'].includes(row.state)" type="primary" size="mini" icon="el-icon-edit" @click="$moe_coordinator.navigateTo(`/activityManage/group/add`, { id: row.id })">编辑</el-button>

          <template v-if="['PENDING'].includes(row.state)">
            <el-button type="warning" size="mini" icon="el-icon-thumb" @click="updateGroupActivityState(row, '发布', 'PUBLISHED')">发布</el-button>
            <el-button type="danger" size="mini" icon="el-icon-delete" @click="delGroupActivity(row)">删除</el-button>
          </template>

          <el-button v-else-if="['NOT_STARTED', 'IN_PROGRESS'].includes(row.state)" type="info" size="mini" icon="el-icon-error" @click="updateGroupActivityState(row, '关闭', 'CLOSED')">关闭</el-button>
        </div>
      </el-table-column>
    </moe-table>
  </moe-page>
</template>

<script>
export default {
  name: 'ActivityManageGroupList',
  data() {
    return {
      activityParams: {
        pageNum: 1,
        pageSize: 10,
        name: '',
        state: '',
      },
    }
  },
  methods: {
    activitySearch(isSearch) {
      if (!isSearch) {
        this.activityParams = {
          pageNum: 1,
          pageSize: 10,
          name: '',
          state: '',
        }
      }

      this.$refs['activityTable'].searchData();
    },

    /** 删除拼团活动 */
    delGroupActivity({ name, id }) {
      this.$moe_layer.confirm(`您确定要删除当前选项“${name}”吗？`, () => {
        this.$moe_api.GROUPACTIVITY_API.delGroupActivity({ id }).then((data) => {
          if (data.code == 200) {
            this.$moe_msg.success('删除成功');
            this.$refs['activityTable'].searchData();
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      });
    },

    /** 更新拼团活动状态 */
    updateGroupActivityState({ name, id, endTime }, text, state) {
      if (state === 'PUBLISHED' && this.$moe_time.dateBefore({ startDate: endTime })) {
        this.$moe_layer.alert(`该活动时间已结束，请重新设置`)
        return false
      }
      this.$moe_layer.confirm(`您确定要${text}当前选项“${name}”吗？`, () => {
        this.$moe_api.GROUPACTIVITY_API.updateGroupActivityState({ id, state }).then((data) => {
          if (data.code == 200) {
            this.$moe_msg.success(`${text}成功`);
            this.$refs['activityTable'].searchData();
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      });
    },
  },
  mounted() {

  }
}
</script>

<style lang="scss" scoped></style>